import React, { useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const BrowserWarning = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);

  const isNonChromeBrowser = () => {
    const { userAgent } = navigator;
    return !userAgent.match(/chrome|chromium|crios/i);
  };

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('browserWarningDismissed', 'true');
  };

  useEffect(() => {
    const isDismissed = sessionStorage.getItem('browserWarningDismissed');
    if (!isDismissed && isNonChromeBrowser()) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        top: 0,
        left: 0,
        padding: '12px 48px',
        backgroundColor: theme.palette.card.main,
        textAlign: 'center',
        boxShadow: '0px 4px 4px 0px #00000040',
        zIndex: 1250
      }}
    >
      <Typography variant="caption1" sx={{ color: '#F09696' }}>
        The current browser is not fully supported by Smart+, and you may
        encounter issues during usage. For the best experience, we recommend
        using Google Chrome. Learn more about supported browsers
        {' '}
        <Link
          href="https://kabamrobotics.atlassian.net/wiki/spaces/KRW/pages/2351431681/Supported+Browsers"
          target="_blank"
          sx={{ color: '#F09696', textDecorationColor: '#F09696' }}
        >
          here
        </Link>
        .
      </Typography>
      <CloseIcon
        stroke={theme.palette.text.secondary}
        onClick={handleClose}
        style={{ cursor: 'pointer' }}
      />
    </Box>
  );
};

export default BrowserWarning;
