import PropTypes from 'prop-types';
import React from 'react';
import { useSearchParams, Navigate, useNavigate } from 'react-router-dom';
// Example Component to handle redirection
const RedirectWithQuery = ({ to }) => {
  const [searchParams] = useSearchParams();
  const authString = searchParams.get('auth');
  const themeString = searchParams.get('theme');
  return <Navigate to={`${to}?theme=${themeString}&auth=${authString}`} />;
};

export const useNavigateWithQuery = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navigateWithQuery = (url, options) => {
    const authString = searchParams.get('auth');
    const themeString = searchParams.get('theme');
    navigate(`${url}?theme=${themeString}&auth=${authString}`, options);
  };
  return navigateWithQuery;
};
RedirectWithQuery.propTypes = {
  to: PropTypes.string.isRequired
};
export default RedirectWithQuery;
