import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { darkPalette, lightPalette } from 'palettes';

import getThemedComponents from 'style-overrides/components';

const generatePalette = (mode) => {
  const basePalette = mode === 'dark' ? darkPalette : lightPalette;

  const urlParams = new URLSearchParams(window.location.search);
  const urlTheme = urlParams.get('theme');
  const copyTheme = { ...basePalette };
  const bgColor = urlParams.get('bg_color');
  const bgBorder = urlParams.get('bg_border');
  const bgSecColor = urlParams.get('bg_sec_color');
  const bgMain = urlParams.get('bg_main');
  const successColor = urlParams.get('success_color');
  const warnColor = urlParams.get('warn_color');
  const errorColor = urlParams.get('error_color');
  const missionColor = urlParams.get('mission_color');
  if (urlTheme) {
    if (bgColor) {
      copyTheme.palette.background.secondary = `#${bgColor}`;
      copyTheme.palette.button.pilotButton = `#${bgColor}`;
      copyTheme.palette.background.pilotButton = `#${bgColor}`;
    }
    if (bgBorder) {
      copyTheme.palette.borders.main = `#${bgBorder}`;
      copyTheme.palette.borders.pilotButton = `#${bgBorder}`;
      copyTheme.palette.borders.table = `#${bgBorder}`;
    }
    if (bgSecColor) {
      copyTheme.palette.background.tabs = `#${bgSecColor}`;
      copyTheme.palette.card.main = `#${bgSecColor}`;
      copyTheme.palette.background.default = `#${bgSecColor}`;
      copyTheme.palette.background.defaultAlpha = `#${bgSecColor}80`;
      copyTheme.palette.background.missionWidget = `#${bgSecColor}`;
      copyTheme.palette.background.widget = `#${bgSecColor}`;
      copyTheme.palette.inputFields.main = `#${bgSecColor}`;
    }
    if (bgMain) {
      copyTheme.palette.primary.main = `#${bgMain}`;
      copyTheme.palette.robotMarker.main = `#${bgMain}`;
      copyTheme.palette.secondary.main = `#${bgMain}`;
    }
    if (successColor) {
      copyTheme.palette.robotOpsIcons.statusGreen = `#${successColor}`;
      copyTheme.palette.text.tableStatus = `#${successColor}`;
      copyTheme.palette.text.tableStatus = `#${successColor}`;
      copyTheme.palette.borders.excellentChip = `#${successColor}`;
      copyTheme.palette.borders.successChip = `#${successColor}`;
    }
    if (warnColor) {
      copyTheme.palette.borders.warningChip = `#${warnColor}`;
      copyTheme.palette.robotOpsIcons.statusYellow = `#${warnColor}`;
      copyTheme.palette.background.warningChip = `#${warnColor}60`;
    }
    if (errorColor) {
      copyTheme.palette.button.delete = `#${errorColor}`;
      copyTheme.palette.robotOpsIcons.statusRed = `#${errorColor}`;
      copyTheme.palette.borders.errorChip = `#${errorColor}`;
      copyTheme.palette.background.warningChip = `#${errorColor}60`;
    }
    if (missionColor) {
      copyTheme.palette.borders.onMissionChip = `#${missionColor}`;
      copyTheme.palette.background.onMissionChip = `#${missionColor}60`;
    }
  }

  return copyTheme;
};
const darkTheme = createTheme(generatePalette('dark'));
const lightTheme = createTheme(generatePalette('light'));

const theme = {
  dark: deepmerge(darkTheme, getThemedComponents(darkTheme)),
  light: deepmerge(lightTheme, getThemedComponents(lightTheme))
};

export default theme;
